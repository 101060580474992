.outerLogo {
    font-family: sans-serif;
    background-color: #003366;
    width: 100%;
    color: black;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.content h1 {
    font-weight: 800;
    font-size: x-large;
    text-align: center;
    color: white;
}

.h4, .p {
    font-weight: 400;
    font-size: medium;
    text-align: center;
    color:aquamarine;
}

.h2 {
    font-weight: 400;
    font-size: medium;
    text-align: center;
    color: white;
}
.h2logo {
    
    font-size: larger;
    text-align: center;
    color: white;
}

.content, .badge {
    padding: 5px;
    flex: 1;
    min-width: 200px;
    box-sizing: border-box;
}

.logo {
    padding: 5px;
    flex: 1;
    min-width: 200px;
    min-height: 150px;
    box-sizing: border-box;
    background-image: url('../images/new-jcbeca-logo.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.content {
    min-height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2px;
    flex: 2;
}

.badge {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100px;
}

.number {
    text-align: center;
    flex: 1;
}

@media (max-width: 768px) {
    .h2 {
        font-size: 11px;
    }

    .h4, .p {
        font-weight: 300;
        font-size: 13px;
    }

    .content h2 {
        font-size: 17px;
    }

    .content h4, p {
        font-size: 15px;
    }

    .badge {
        flex-direction: column;
        height: auto;
    }

    .logo {
        width: 100%;
        height: 100px;
    }

    .content, .number {
        width: 100%;
    }

    .number h2, h3, p {
        font-size: 15px;
    }
}
