*{
    margin: 0;
    font-family:'Times New Roman', Times, serif;
    scroll-behavior: smooth;
}


.App {

  background: linear-gradient(to bottom, #003366 30%, #87cefa 70%, #ffffff); /* Dark blue to lighter blue, then to white */
    padding: 50px; 
    color: #FFFFFF; 
    min-height: 100vh; 
    box-sizing: border-box; 
    margin: 0;
    border:0;
    display: flex; 
    flex-direction: column; 
  }
  
  @media (max-width: 600px) {
    .App {
      font-size: 12px; 
      padding: 10px;
    }
  }


  h2 {
    position: relative;
    display: inline-block;
    color: gold;
  }
  @media (max-width: 500px) {
    h2 ,h2,h3 {
      font-size: 2px; /* Adjust as needed for mobile screens */
    }
  }


/* Hover effect to scale the box */
