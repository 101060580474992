*{
    margin: 0;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}
.boxStyle {
  background-color:  #03396c;
  color: #03396c;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 30px auto;
  text-align: left;
  line-height: 1.4;
  font-size: 18px;
  text-align: justify;
  overflow: hidden; 
  color: white;
}

.imageContainer {
  float: left;
  margin-right: 20px; 
  
}

.imageContainer img {
  width: 300px; 
  height: 350px;
}

.textContainer {
  overflow: hidden; 
}

.boxStyle h2 {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 480px) {
  .boxStyle {
    font-size: 13px;
    padding: 20px;
    margin: 20px auto;
  }
  .imageContainer {
    float: none;
    margin-right: 0;
    margin-bottom: 15px; 
    text-align: center;
  }
  .imageContainer img {
    width: 100%; 
    height: auto;
  }
}


  .visionBox {
    background-color: #03396c; 
    color: #03396c;
    padding: 30px; 
    border-radius: 10px; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
    max-width: 90%; 
    margin: 30px auto; 
    text-align: left;
    line-height: 1.7;
    font-size: 18px; 
    text-align: justify;
    color: white;

  }
  
  .visionBox h2 {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 700;
    color: gold;
  }
  
  .visionBox h3 {
    font-weight:500 ;
    color:gold;
  }
  
  .visionBox ul {
    padding-left: 20px; 
    list-style-type:square;
  }
  
  @media (max-width: 768px) {
    .visionBox {
      font-size: 16px;
      padding: 20px; 
    }
  
    .visionBox h2 {
      font-size: 24px; 
    }
  
    .visionBox h3 {
      font-size: 20px; 
    }
  }
  
  @media (max-width: 480px) {
    .visionBox {
      font-size: 13px; 
      padding: 15px; 
    }
  
    .visionBox h2 {
      font-size: 18px; 
    }
  
    .visionBox h3 {
      font-size: 15px; 
    }
  }
  
  .featuresBox {
    background-color: #03396c; 
    color: #03396c; 
    padding: 30px; 
    border-radius: 10px; 
    box-shadow: 0 8px 16px rgba(0,0,0,0.1); 
    max-width: 90%;
    margin: 30px auto;
    text-align: left; 
    line-height: 1.4; 
    font-size: 18px; 
    text-align: justify;
    color:white;

  }
  
  .featuresBox h2 {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 700;
    color: white; 
  }
  
  .featuresBox ul {
    list-style-type: square;
    padding-left: 20px; 
  }
  
  @media (max-width: 480px) {
    .featuresBox {
      font-size: 13px; 
      padding: 20px; 
      margin: 20px auto; 
      max-width: 90%; 
    }
    .featuresBox h2 {
      font-size: 18px;
    }
  }
  
  

  .tableBox {
    font-size: 18px;
    background-color: #03396c; 
    padding: 30px;
    border-radius: 10px; 
    box-shadow: 0 8px 16px rgba(0,0,0,0.1); 
    margin-bottom: 30px;
    line-height: 1.8; 
    text-align: justify;
    color:white;
    
  }
  .tableBox h2 {
    color:white;
    font-size: 24px;
  }
  .tableStyle {
    width: 100%;
    border-collapse: collapse; 
    margin-top: 20px; 
    overflow-x: auto; 
  }
  
  .thStyle {
    background-color: #022B3A; 
    color: #FFFFFF; 
    padding: 10px; 
  }
  
  .tdStyle {
    border: 1px solid #ddd; 
    padding: 20px; 
    text-align: left; 
    
  }
  .tableBox ul li{
    list-style-type: square;
}

  @media (max-width: 480px) {
    .tableBox {
      padding: 20px; 
      font-size: 14px;
    }
  
    .tableStyle {
      display: block; 
      width: auto; 
    }
    .tableBox ul li{
        font-size: 10px;
    }
  
    .thStyle,
    .tdStyle {
      padding: 8px; 
    }
  
    .tdStyle {
      border: none; 
      border-bottom: 1px solid #ddd; 
      padding-top: 12px; 
      padding-bottom: 12px; 
    }
  
    .tdStyle:last-child {
      border-bottom: none; 
    }
  }


.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #03396c;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 30px auto;
  color: white;
  overflow: hidden;
}

/* Base Styles for Box */
.box {
  flex: 1;
  margin: 0 10px;
  text-align: justify;
  line-height: 1.4;
  font-size: 18px;
  padding: 20px;
  border-radius: 10px;
}


/* Base Styles for Heading */
.heading {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .box {
    margin: 10px 0;
  }
}




.featuresBox h2 {
  color: gold;
}

.tableBox h2{
  color: gold;
}