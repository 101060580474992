.guidelinesBox, .submissionBox, .manuscript, .titlePage, .AuthourPage, .AbstractPage, .Keyword, .Literature, .Proposals, .Equations, .Figure, .Table, .research {
  background-color: #03396c; /* Background color */
  color: white; /* Text color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 30px auto;
  text-align: justify;
  font-size: 18px; /* Base font size */
  line-height: 1.6; /* Line height for readability */
}
.guidelinesBox li{
  list-style-type: circle;
}

.guidelinesBox h2, .submissionBox h2, .manuscript h2, .titlePage h2, .AuthourPage h2, .AbstractPage h2, .Keyword h2, .Literature h2, .Proposals h2, .Equations h2, .Figure h2, .Table h2, .research h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.manuscript ul ul li {
  list-style-type: decimal;
}

.manuscript li {
  list-style-type: circle;
}

.guidelinesBox p, .submissionBox p, .manuscript h3, .manuscript ul li, .titlePage p, .AuthourPage p, .AbstractPage p, .Keyword p, .Literature p, .Proposals p, .Equations p, .Figure p, .Table p, .research p {
  margin-bottom: 8px;
}

.manuscript h3 {
  font-size: 20px; /* Larger font size for subheadings */
  font-weight: bold;
}

.manuscript ul {
  list-style-type: none;
  padding: 0;
}

.manuscript ul ul {
  margin-left: 20px;
}

.Keyword a {
  color: red;
  text-decoration: underline; /* Underlined for emphasis */
}

@media (max-width: 768px) {
  .guidelinesBox, .submissionBox, .manuscript, .titlePage, .AuthourPage, .AbstractPage, .Keyword, .Literature, .Proposals, .Equations, .Figure, .Table, .research {
    font-size: 16px; /* Slightly smaller font for tablets */
    padding: 15px; /* Reduced padding */
  }

  .guidelinesBox h2, .submissionBox h2, .manuscript h2, .titlePage h2, .AuthourPage h2, .AbstractPage h2, .Keyword h2, .Literature h2, .Proposals h2, .Equations h2, .Figure h2, .Table h2, .research h2 {
    font-size: 22px; /* Adjusted heading size */
  }

  .manuscript h3 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .guidelinesBox, .submissionBox, .manuscript, .titlePage, .AuthourPage, .AbstractPage, .Keyword, .Literature, .Proposals, .Equations, .Figure, .Table, .research {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 10px; /* Reduced padding for mobile */
  }

  .guidelinesBox h2, .submissionBox h2, .manuscript h2, .titlePage h2, .AuthourPage h2, .AbstractPage h2, .Keyword h2, .Literature h2, .Proposals h2, .Equations h2, .Figure h2, .Table h2, .research h2 {
    font-size: 20px; /* Smaller heading size for mobile */
  }

  .manuscript h3 {
    font-size: 16px; /* Adjusted subheading size for mobile */
  }
}

.buttonButtonouter{
  background-color: #03396c; /* Background color */
  color: white; /* Text color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 30px auto;
  text-align: justify;
  font-size: 18px; /* Base font size */
  line-height: 1.6; /* Line height for readability */

}



.buttonStyle {
  width: 100%;
  height: 100%;
  padding: 15px 25px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #ff6347; /* Tomato color */
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  animation: pulse 1.5s infinite;
}

.buttonStyle:hover {
  background-color: #ff4500; /* Darker tomato color */
  transform: scale(1.1); /* Slightly enlarges on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Adds shadow on hover */
}

.buttonStyle:active {
  transform: scale(0.95);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 99, 71, 0.7);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 99, 71, 0.9);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 99, 71, 0.7);
  }
}

@media (max-width: 768px) {
  .buttonStyle {
    font-size: 16px;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .buttonStyle {
    font-size: 14px;
    padding: 10px 18px;
  }
}
