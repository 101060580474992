/* Base Styles */
.container {
  padding: 20px;
  font-family: 'Verdana', Geneva, Tahoma, sans-serif;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.previewButton,
.downloadButton {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
}

.previewButton:hover,
.downloadButton:hover {
  background-color: #03396c;
}

.card {
  background-color: #03396c;
  padding: 20px;
  border-radius: 8px;
  color: white;
  margin-bottom: 20px;
}

.cardHeader {
  color: yellow;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.cardHeader h1 {
  font-size: 32px;
  /* Larger font size for title */
  color: yellow;
}

.cardBody {
  padding: 10px;
  border-radius: 8px;
}

.cardBody h3 {
  color: yellow;
  font-size: 20px;
  margin-bottom: 5px;
}

.cardBody p {
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
}

.cardBody a {
  color: white;
  text-decoration: underline;
}

.iframeContainer {
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #03396c;
}

.paperPreviewTitle {
  text-align: center;
  color: yellow;
  font-size: 24px;
  /* Heading size for Paper Preview */
}

iframe {
  width: 100%;
  height: 750px;
  border-radius: 8px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  .card {
    padding: 15px;
  }

  .iframeContainer {
    padding: 15px;
  }

  iframe {
    height: 400px;
  }

  .cardHeader h1 {
    font-size: 28px;
  }

  .cardBody h3 {
    font-size: 18px;
  }

  .cardBody p {
    font-size: 16px;
  }

  .cardBody a {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }

  .card {
    padding: 10px;
  }

  iframe {
    height: 300px;
  }

  .cardHeader h1 {
    font-size: 20px;
  }

  .cardBody h3 {
    font-size: 16px;
  }

  .cardBody p {
    font-size: 14px;
  }

  .cardBody a {
    font-size: 14px;
  }

  .paperPreviewTitle {
    font-size: 20px;
  }
}





.referencesCard {
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  color: white;
}

.referencesCard h3 {
  color: yellow;
  font-size: 20px;
  margin-bottom: 5px;
}

.referencesList {
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 15px;
  text-align: left;
}

.referencesList span {
  display: block;
  margin-bottom: 10px;
  /* Space between references */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .referencesCard {
    padding: 15px;
  }

  .referencesCard h3 {
    font-size: 1.25rem;
  }

  .referencesList {
    font-size: 0.9rem;
  }
}